import dayjs from "dayjs";
import { batch, createMemo, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import type { ExperimentsService } from "~/domains/experiments";
import type { IdentityService } from "~/domains/identity/service";

type LimitingServiceDeps = {
  identity: IdentityService;
  experiments: ExperimentsService;
};

export const useLimitingService = (deps: LimitingServiceDeps) => {
  const guest = useGuestLimits(deps);

  return { guest };
};

export type LimitingService = ReturnType<typeof useLimitingService>;

const useGuestLimits = (deps: LimitingServiceDeps) => {
  const isGuest = () => deps.identity.snapshot.context.identity.isGuest;
  const experiments = deps.experiments.data;
  const localKeyPerDay = `interactions_limit:v1:${dayjs().format("YYYY-MM-DD")}`;

  const [limits, setLimits] = createStore({
    allowedThreads: [] as string[],
    interactions: 0,
    lastInteraction: undefined as undefined | { type: "prompt"; prompt: string } | { type: "knowledge" },
  });

  /**
   * Get localstorage info cached by day, to see what the rate limit is for the guest user
   */
  onMount(() => {
    try {
      const limit = localStorage?.getItem(localKeyPerDay);

      const parsed = JSON.parse(limit || "") as unknown;
      if (
        typeof parsed !== "object" ||
        !parsed ||
        !("interactions" in parsed) ||
        typeof parsed.interactions !== "number" ||
        !("allowedThreads" in parsed) ||
        !Array.isArray(parsed.allowedThreads)
      )
        return;

      const interactions = parsed.interactions;
      const allowedThreads = parsed.allowedThreads;

      setLimits({
        interactions,
        allowedThreads,
      });
    } catch (error) {}
  });

  const updateLocalStorage = () => {
    localStorage?.setItem(
      localKeyPerDay,
      JSON.stringify({
        ...limits,
      }),
    );
  };

  const incrementInteractions = (interactionType?: (typeof limits)["lastInteraction"]) => {
    if (!isGuest()) return;

    batch(() => {
      setLimits("interactions", (i) => i + 1);
      setLimits("lastInteraction", interactionType);
    });
    updateLocalStorage();
  };

  const lastInteraction = createMemo(() => limits.lastInteraction);
  const resetLastInteraction = () => setLimits("lastInteraction", undefined);

  const isInteractionAllowed = createMemo(() => {
    if (!isGuest()) return true;

    const variant = experiments()?.["limit-anonymous-access"]?.variant;

    const maxInteractions = variant === "max-5" ? 5 : variant === "max-10" ? 10 : undefined;
    if (maxInteractions === undefined) return true;

    return limits.interactions < maxInteractions;
  });

  const isThreadAllowed = (threadId: string) => {
    if (!isGuest()) return true;
    const variant = experiments()?.["limit-anonymous-access"]?.variant;
    const limited = variant === "max-5" || variant === "max-10";
    if (!limited) return true;

    return limits.allowedThreads.includes(threadId);
  };

  const addAllowedThread = (threadId: string) => {
    if (!isGuest()) return;

    setLimits("allowedThreads", (a) => [...a, threadId]);
    updateLocalStorage();
  };

  return {
    lastInteraction,
    resetLastInteraction,
    incrementInteractions,
    addAllowedThread,
    isThreadAllowed,
    isInteractionAllowed,
    interactionsCount: () => limits.interactions,
  };
};
