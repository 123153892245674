// Code generated by the Encore v1.41.4 client generator. DO NOT EDIT.

// Disable eslint, jshint, and jslint for this file.
/* eslint-disable */
/* jshint ignore:start */
/*jslint-disable*/

/**
 * BaseURL is the base URL for calling the Encore application's API.
 */
export type BaseURL = string

export const Local: BaseURL = "http://localhost:4000"

/**
 * Environment returns a BaseURL for calling the cloud environment with the given name.
 */
export function Environment(name: string): BaseURL {
    return `https://${name}-storytell-ai-platfor-cds2.encr.app`
}

/**
 * PreviewEnv returns a BaseURL for calling the preview environment with the given PR number.
 */
export function PreviewEnv(pr: number | string): BaseURL {
    return Environment(`pr${pr}`)
}

/**
 * Client is an API client for the storytell-ai-platfor-cds2 Encore application. 
 */
export default class Client {
    public readonly controlplane: controlplane.ServiceClient


    /**
     * @deprecated This constructor is deprecated, and you should move to using BaseURL with an Options object
     */
    constructor(target: string, token?: string)

    /**
     * Creates a Client for calling the public and authenticated APIs of your Encore application.
     *
     * @param target  The target which the client should be configured to use. See Local and Environment for options.
     * @param options Options for the client
     */
    constructor(target: BaseURL, options?: ClientOptions)
    constructor(target: string | BaseURL = "prod", options?: string | ClientOptions) {

        // Convert the old constructor parameters to a BaseURL object and a ClientOptions object
        if (!target.startsWith("http://") && !target.startsWith("https://")) {
            target = Environment(target)
        }

        if (typeof options === "string") {
            options = { auth: options }
        }

        const base = new BaseClient(target, options ?? {})
        this.controlplane = new controlplane.ServiceClient(base)
    }
}

/**
 * ClientOptions allows you to override any default behaviour within the generated Encore client.
 */
export interface ClientOptions {
    /**
     * By default the client will use the inbuilt fetch function for making the API requests.
     * however you can override it with your own implementation here if you want to run custom
     * code on each API request made or response received.
     */
    fetcher?: Fetcher

    /** Default RequestInit to be used for the client */
    requestInit?: Omit<RequestInit, "headers"> & { headers?: Record<string, string> }

    /**
     * Allows you to set the auth token to be used for each request
     * either by passing in a static token string or by passing in a function
     * which returns the auth token.
     *
     * These tokens will be sent as bearer tokens in the Authorization header.
     */
    auth?: string | AuthDataGenerator
}

export namespace controlplane {
    export type CommandKey = string

    export interface DeviceID {
        id: string
    }

    export interface GetRecentAssetsForUserParams {
        Page: number
        Sort: string
    }

    export interface GetRecentThreadsForUserParams {
        Page: number
    }

    export interface QueryAssetsForProjectParams {
        Limit: number
        Offset: number
        SortBy: string
        SortDir: string
        Search: string
    }

    export interface ServerEnvelope {
        commandKey: CommandKey
        recipients: string[]
        data: string
    }

    export class ServiceClient {
        private baseClient: BaseClient

        constructor(baseClient: BaseClient) {
            this.baseClient = baseClient
        }

        /**
         * AuthSync creates a new user using the authorized identity. This endpoint is intended to be used by a new user
         * who has established their credentials with the auth service.
         */
        public async AuthSync(params: users.UserAuthSyncCmdParams): Promise<operations.Response<users.AuthSyncResult>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/users/self/auth-sync`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<users.AuthSyncResult>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * CreateAsset creates a new asset. It returns the state of the asset pending upload. Use the signedURL to upload the asset.
         */
        public async CreateAsset(params: projects.CreateAssetCmdParams): Promise<operations.Response<projects.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/projects/{project_id}/assets`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<projects.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * CreateThread creates a new thread. The thread will be created within the organization claimed in the auth token.
         */
        public async CreateThread(params: threads.CreateThreadCmdParams): Promise<operations.Response<threads.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/threads`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<threads.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * DeleteThread removes a thread from the system.
         */
        public async DeleteThread(threadID: string): Promise<operations.Response<boolean>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("DELETE", `/v1/threads/${encodeURIComponent(threadID)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<boolean>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Fallback is called for the debug endpoints.
         */
        public async Fallback(method: string, fallback: string[], body?: BodyInit, options?: CallParameters): Promise<Response> {
            return this.baseClient.callAPI(method, `/${fallback.map(encodeURIComponent).join("/")}`, body, options)
        }

        /**
         * GenIDs is an endpoint that has no use. It exists to aid code generation.
         */
        public async GenIDs(params: stid.Bag): Promise<session.Session> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/_/gen/ids_validate`, JSON.stringify(params))
            return await resp.json() as session.Session
        }

        /**
         * GenServerEnvelope has no practical use. It exists to aid code generation.
         */
        public async GenServerEnvelope(): Promise<ServerEnvelope> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/_/gen/server_envelope`)
            return await resp.json() as ServerEnvelope
        }

        /**
         * GenThreadContentTypes has no practical use. It exists to aid code generation.
         */
        public async GenThreadContentTypes(): Promise<threads.MessageKinds> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/_/gen/threads`)
            return await resp.json() as threads.MessageKinds
        }

        /**
         * GenerateDeviceID generates a new device ID.
         */
        public async GenerateDeviceID(): Promise<DeviceID> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/device/generate-id`)
            return await resp.json() as DeviceID
        }

        /**
         * GenerateSignedURLForMessageDiagnostics returns a signed URL for message diagnostics for Administrators.
         */
        public async GenerateSignedURLForMessageDiagnostics(messageIDString: string): Promise<operations.Response<string>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/diagnostics/message/${encodeURIComponent(messageIDString)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<string>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        public async GetAssetByID(assetID: string): Promise<operations.Response<q.ControlplaneSsAsset>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/assets/${encodeURIComponent(assetID)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<q.ControlplaneSsAsset>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * GetAssetsRecentForUser returns assets recently referenced by the user.
         */
        public async GetAssetsRecentForUser(params: GetRecentAssetsForUserParams): Promise<operations.Response<q.ControlplaneSsAsset[]>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                page: String(params.Page),
                sort: params.Sort,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/navigate/recent/assets`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<q.ControlplaneSsAsset[]>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * GetThreadByID retrieves a thread by its ID.
         * The user must be authenticated and focused on the tenant that owns the thread to avoid a permission denied error.
         */
        public async GetThreadByID(threadID: string): Promise<operations.Response<threads.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/threads/${encodeURIComponent(threadID)}`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<threads.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * GetThreadsRecentForUser returns recent threads for the user.
         */
        public async GetThreadsRecentForUser(params: GetRecentThreadsForUserParams): Promise<operations.Response<q.GetRecentThreadsForUserRow[]>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                page: String(params.Page),
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/navigate/recent/threads`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<q.GetRecentThreadsForUserRow[]>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * HandleThreadMessage receives a message in a thread.
         */
        public async HandleThreadMessage(threadID: string, params: threads.MessageCmdParams): Promise<operations.Response<threads.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/threads/${encodeURIComponent(threadID)}/messages`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<threads.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * NotifyAssetUploaded notifies the server that an asset has been uploaded. It is important to call this endpoint
         * after uploading an asset to ensure the asset is fingerprinted and indexed so it can be used within the project.
         */
        public async NotifyAssetUploaded(params: projects.OnUploadAssetCmdParams): Promise<operations.Response<projects.AssetState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/projects/notifyUploaded`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<projects.AssetState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Playground is a placeholder endpoint for testing.
         */
        public async Playground(params: projects.PlaygroundInput): Promise<documents.TextChunks> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                "asset_id":        params.assetId,
                "organization_id": params.organizationId,
                "project_id":      params.projectId,
                prompt:            params.prompt,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/playground`, undefined, {query})
            return await resp.json() as documents.TextChunks
        }

        /**
         * ProjectSnapshots has no practical use. It exists to aid code generation.
         */
        public async ProjectSnapshots(): Promise<projects.Snapshots> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/_/gen/project_snapshots`)
            return await resp.json() as projects.Snapshots
        }

        /**
         * PurgeThreads removes all threads that have been soft-deleted.
         */
        public async PurgeThreads(): Promise<operations.Response<number>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("DELETE", `/v1/purge/threads`)

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<number>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * CreateAsset creates a new asset. It returns the state of the asset pending upload. Use the signedURL to upload the asset.
         */
        public async QueryAssetsForProject(projectId: string, params: QueryAssetsForProjectParams): Promise<operations.Response<q.ControlplaneSsAsset[]>> {
            // Convert our params into the objects we need for the request
            const query = makeRecord<string, string | string[]>({
                limit:   String(params.Limit),
                offset:  String(params.Offset),
                search:  params.Search,
                sortBy:  params.SortBy,
                sortDir: params.SortDir,
            })

            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("GET", `/v1/projects/${encodeURIComponent(projectId)}/assets`, undefined, {query})

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<q.ControlplaneSsAsset[]>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * RenameThread creates a new thread. The thread will be created within the organization claimed in the auth token.
         */
        public async RenameThread(threadID: string, params: threads.RenameThreadCmdParams): Promise<operations.Response<threads.ThreadState>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/threads/${encodeURIComponent(threadID)}/label`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<threads.ThreadState>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * SaveDiagnostics saves diagnostics data to the blob store and returns a signed URL to access the data. Intended for
         * use when users provide feedback.
         */
        public async SaveDiagnostics(params: projects.DiagnosticParams): Promise<operations.Response<projects.DiagnosticResponse>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/diagnostics/save`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<projects.DiagnosticResponse>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * ShareThread shares a thread via an external communication method.
         * 
         * Supported methods: email.
         */
        public async ShareThread(threadID: string, params: threads.ShareThreadCmdParams): Promise<operations.Response<boolean>> {
            // Now make the actual call to the API
            const resp = await this.baseClient.callAPI("POST", `/v1/threads/${encodeURIComponent(threadID)}/share`, JSON.stringify(params))

            //Populate the return object from the JSON body and received headers
            const rtn = await resp.json() as operations.Response<boolean>
            rtn.OperationID = mustBeSet("Header `x-storytell-erroperation-id`", resp.headers.get("x-storytell-erroperation-id"))
            rtn.Duration = mustBeSet("Header `x-storytell-duration-ms`", resp.headers.get("x-storytell-duration-ms"))
            return rtn
        }

        /**
         * Websocket is a public API method that manages a websocket connection.
         */
        public async Websocket(method: "GET", body?: BodyInit, options?: CallParameters): Promise<Response> {
            return this.baseClient.callAPI(method, `/v1/ws`, body, options)
        }
    }
}

export namespace ai {
    export type Model = string

    export type Vendor = string
}

export namespace assets {
    export type ChunkingStrategy = string

    export type ExtractionStrategy = string

    export type KeywordStrategy = string

    export type LifecycleState = string

    export type StorageStrategy = string

    export type SummaryStrategy = string

    export type WorkerStrategy = string
}

export namespace documents {
    export interface TextChunkContent {
        text: string
    }

    /**
     * TextChunks represents the content of an asset that has been processed
     * and will be saved onto disk.
     */
    export interface TextChunks {
        assetId: stid.AssetStringID
        processedBy: string
        chunkedBy: string
        processedAt: string
        length: number
        content: TextChunkContent[]
        metadata: { [key: string]: string }
    }
}

export namespace operations {
    export interface Consumption {
        units: number
    }

    export interface Details {
        operation: ResponseOperation
        consumption: Consumption
    }

    export interface Response<T> {
        OperationID: string
        Duration: string
        eventKey: string
        code: string
        message: string
        details: Details
        data: T
    }

    export interface ResponseOperation {
        operationId: string
        duration: number
    }
}

export namespace permissions {
    export type Action = string

    export interface Grant {
        id: stid.PermissionStringID
        user: stid.UserStringID
        team: stid.TeamStringID
        actions: Action[]
    }

    export type Set = Grant[]
}

export namespace projects {
    export type AssetCreationMethod = string

    export interface AssetMetadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        uploadedAt: string
        extract: ProcessingMetadata
        transcribe: ProcessingMetadata
        summarize: ProcessingMetadata
        embeddings: ProcessingMetadata
        lastReadyAt: string
        lastReferencedAt: string
        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
    }

    /**
     * AssetReference represents an individual reference to an asset while working with a thread. A new instance should be
     * created for each reference within a thread -- but, only one per thread. If the asset is still available within the
     * vendor from a previous reference the existing vendor identifier may be reused to avoid duplicating the asset within
     * the vendor's store.
     */
    export interface AssetReference {
        referenceId: stid.AssetReferenceStringID
        vendor: ai.Vendor
        vendorIdentifier: string
        referencedFor: stid.ThreadStringID
        referencedAt: string
        referencedBy: stid.UserStringID
    }

    export interface AssetState {
        metadata: AssetMetadata
        id: stid.AssetStringID
        /**
         * ProjectID The id of the project this asset belongs to.
         */
        projectID: stid.ProjectStringID

        /**
         * TenantID The id of the tenant the organization belongs to.
         */
        tenantId: stid.OrganizationStringID

        /**
         * OrganizationID The id of the organization the asset belongs to.
         */
        organizationId: stid.OrganizationStringID

        /**
         * Version The version of the organization.
         */
        version: number

        /**
         * ETag The entity tag for the organization. Used for cache invalidation.
         */
        etag: string

        /**
         * OriginalFileName The name of the asset as it was provided by the user originally. This is the name of the file
         * on disk when it was on the user's system.
         */
        originalFileName: string

        /**
         * DisplayName The display name of the asset. This is the name that is shown to users and may be sourced from
         * content within the asset.
         */
        displayName: string

        /**
         * LifecycleState The current lifecycle state of the asset. Assigned by the server.
         */
        lifecycleState: assets.LifecycleState

        /**
         * LifecyclesCompleted The lifecycles that have been completed for the asset.
         */
        lifecyclesCompleted: assets.LifecycleState[]

        /**
         * Summary The summary of the asset. This is a short description of the asset.
         * mMust be less than 2048 characters.
         */
        summary: string

        /**
         * FileSize The size of the file in bytes.
         */
        fileSize: number

        /**
         * TextTokens number of tokens in the asset's text content.
         */
        textTokens: number

        /**
         * ContentType The type of the file. This is the MIME type of the file.
         */
        contentType: string

        /**
         * Permissions The permissions that have been granted to the asset. Access may also be granted to the asset's
         * parent directory. These permissions are exclusive to the asset. If there is a conflict between the permissions
         * of the asset and the parent directory, the asset's permissions will take precedence.
         */
        permissions: permissions.Set

        /**
         * SignedURL The signed URL for the asset. This is a temporary URL that can be used to access the asset.
         */
        signedURL: string

        failureReason: string
        /**
         * Embeddings The embeddings for the asset. These are generated from the asset and are used to augment conversations.
         */
        embeddings: Embeddings

        /**
         * VendorReferences is a listing of references to this asset by vendor. Vendors may retain a file only for a short
         * period of time. This is a cache of the identifiers used by the vendor to reference the asset. The validity of
         * these identifiers should be confirmed by the vendor before use and the reference dropped or updated.
         */
        references: AssetReference[]

        keywords: string[]
        /**
         * ProcessingMessage is the last message from the processing pipeline. It may be removed when processing is complete.
         */
        processingMessage: string

        /**
         * ProcessingErrMessage is the last error message from the processing pipeline. It may be removed when processing is complete.
         */
        processingErrMessage: string

        storageStrategy: assets.StorageStrategy
        workerStrategy: assets.WorkerStrategy
        chunkingStrategy: assets.ChunkingStrategy
        extractionStrategy: assets.ExtractionStrategy
        summaryStrategy: assets.SummaryStrategy
        keywordStrategy: assets.KeywordStrategy
        producedHTMLArtifact: boolean
        producedMarkdownArtifact: boolean
        producedTranscriptionArtifact: boolean
        producedEmbeddings: boolean
        producedSummary: boolean
        producedKeywords: boolean
        htmlArtifactTokenCount: number
        markdownTokenCount: number
        summaryTokenCount: number
        keywordsTokenCount: number
        transcriptionTokenCount: number
    }

    /**
     * CreateAssetCmdParams is the params for the CreateAsset command.
     */
    export interface CreateAssetCmdParams {
        originalFileName: string
        contentType: string
        creationMethod: AssetCreationMethod
        projectID: stid.ProjectStringID
        permissions: permissions.Set
    }

    export interface DiagnosticParams {
        /**
         * Data should be JSON encoded but can be any diagnostics represented as a string
         */
        data: string
    }

    export interface DiagnosticResponse {
        url: string
    }

    export interface Embedding {
        state: EmbeddingLifecycleState
        model: string
    }

    export type EmbeddingLifecycleState = string

    export type Embeddings = Embedding[]

    /**
     * OnUploadAssetCmdParams is the params for the OnUploadAsset command.
     */
    export interface OnUploadAssetCmdParams {
        assetID: string
        /**
         * ProcessInBackground is a flag that indicates whether the asset should be processed in the background. If false,
         * the trigger to process the asset must occur through the threads domain as knowledge is added. Otherwise, the
         * system will begin processing async in the background.
         */
        processInBackground: boolean
    }

    export interface PlaygroundInput {
        organizationId: string
        projectId: string
        assetId: string
        prompt: string
    }

    export interface ProcessingMetadata {
        startAt: string
        completedAt: string
        failedAt: string
        failedReason: string
        /**
         * http codes
         */
        statusCode: number
    }

    /**
     * Snapshots serves primarily to aid in client side code generation
     */
    export interface Snapshots {
        assetSnapShot: q.ControlplaneSsAsset
    }
}

export namespace q {
    export interface ControlplaneSsAsset {
        id: string
        userId: string
        userDisplayName: string
        projectId: string
        organizationId: string
        tenantId: string
        version: number
        etag: string
        originalFilename: string
        displayName: string
        filesize: number
        contentType: string
        summary: string
        lifecycleState: string
        createdAt: string
        modifiedAt: string
        processingFailureReason: sql.NullString
        processingStatusText: sql.NullString
        textTokens: number
        deletedAt: sql.NullTime
    }

    export interface GetRecentThreadsForUserRow {
        threadId: string
        label: string
        messageCount: number
        createdAt: string
        modifiedAt: string
        accessedAt: string
    }
}

export namespace session {
    export interface Attribution {
        ProxiedBy: stid.UserStringID
        TenantID: stid.OrganizationStringID
        OrganizationID: stid.OrganizationStringID
    }

    export interface Session {
        ID: stid.UserStringID
        ExternalAuthID: string
        ExternalAuthSystem: string
        DisplayName: string
        Email: string
        IsEmailVerified: boolean
        PictureURL: string
        IdentityToken: string
        Attribution: Attribution
        HasCustomClaims: boolean
    }
}

export namespace slugs {
    export type Slug = string
}

export namespace sql {
    /**
     * NullString represents a string that may be null.
     * NullString implements the [Scanner] interface so
     * it can be used as a scan destination:
     * 
     * 	var s NullString
     * 	err := db.QueryRow("SELECT name FROM foo WHERE id=?", id).Scan(&s)
     * 	...
     * 	if s.Valid {
     * 	   // use s.String
     * 	} else {
     * 	   // NULL value
     * 	}
     */
    export interface NullString {
        String: string
        /**
         * Valid is true if String is not NULL
         */
        Valid: boolean
    }

    /**
     * NullTime represents a [time.Time] that may be null.
     * NullTime implements the [Scanner] interface so
     * it can be used as a scan destination, similar to [NullString].
     */
    export interface NullTime {
        Time: string
        /**
         * Valid is true if Time is not NULL
         */
        Valid: boolean
    }
}

export namespace stid {
    /**
     * Asset is a type safe ID.
     */
    export interface Asset {
    }

    /**
     * AssetReference is a type safe ID.
     */
    export interface AssetReference {
    }

    /**
     * AssetReferenceStringID is a string type for the AssetReference ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type AssetReferenceStringID = string

    /**
     * AssetStringID is a string type for the Asset ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type AssetStringID = string

    /**
     * Bag is a collection of ID types. It is mainly used to influence the encore generator to generate the
     * the relevant string types.
     */
    export interface Bag {
        UserStringID: UserStringID
        OrganizationStringID: OrganizationStringID
        ProjectStringID: ProjectStringID
        DeviceStringID: DeviceStringID
        OperationStringID: OperationStringID
        EventStringID: EventStringID
        AssetStringID: AssetStringID
        DirectoryStringID: DirectoryStringID
        TeamStringID: TeamStringID
        PermissionStringID: PermissionStringID
        InteractionStringID: InteractionStringID
        SkuStringID: SkuStringID
        RequestStringID: RequestStringID
        TagStringID: TagStringID
        ChunkStringID: ChunkStringID
        ThreadStringID: ThreadStringID
        MessageStringID: MessageStringID
        StepStringID: StepStringID
        AssetReferenceStringID: AssetReferenceStringID
        KeywordStringID: KeywordStringID
        HistoryStringID: HistoryStringID
        DiagnosticStringID: DiagnosticStringID
        MessageStepStringID: MessageStepStringID
    }

    /**
     * ChunkStringID is a string type for the Chunk ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ChunkStringID = string

    /**
     * DeviceStringID is a string type for the Device ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type DeviceStringID = string

    /**
     * DiagnosticStringID is a string type for the Diagnostic ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type DiagnosticStringID = string

    /**
     * Directory is a type safe ID.
     */
    export interface Directory {
    }

    /**
     * DirectoryStringID is a string type for the Directory ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type DirectoryStringID = string

    /**
     * EventStringID is a string type for the Event ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type EventStringID = string

    /**
     * HistoryStringID is a string type for the History ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type HistoryStringID = string

    export type ID<T> = xid.ID

    /**
     * Interaction is a type safe ID.
     */
    export interface Interaction {
    }

    /**
     * InteractionStringID is a string type for the Interaction ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type InteractionStringID = string

    /**
     * KeywordStringID is a string type for the Keyword ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type KeywordStringID = string

    /**
     * Message is a type safe ID.
     */
    export interface Message {
    }

    /**
     * MessageStepStringID is a string type for the MessageStep ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type MessageStepStringID = string

    /**
     * MessageStringID is a string type for the Message ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type MessageStringID = string

    /**
     * OperationStringID is a string type for the Operation ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type OperationStringID = string

    /**
     * Organization is a type safe ID.
     */
    export interface Organization {
    }

    /**
     * OrganizationStringID is a string type for the Organization ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type OrganizationStringID = string

    /**
     * Permission is a type safe ID.
     */
    export interface Permission {
    }

    /**
     * PermissionStringID is a string type for the Permission ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type PermissionStringID = string

    /**
     * Project is a type safe ID.
     */
    export interface Project {
    }

    /**
     * ProjectStringID is a string type for the Project ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ProjectStringID = string

    /**
     * RequestStringID is a string type for the Request ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type RequestStringID = string

    /**
     * SkuStringID is a string type for the Sku ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type SkuStringID = string

    /**
     * StepStringID is a string type for the Step ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type StepStringID = string

    /**
     * TagStringID is a string type for the Tag ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type TagStringID = string

    /**
     * Team is a type safe ID.
     */
    export interface Team {
    }

    /**
     * TeamStringID is a string type for the Team ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type TeamStringID = string

    /**
     * Thread is a type safe ID.
     */
    export interface Thread {
    }

    /**
     * ThreadStringID is a string type for the Thread ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type ThreadStringID = string

    /**
     * User is a type safe ID.
     */
    export interface User {
    }

    /**
     * UserStringID is a string type for the User ID. It is used to help avoid confusion with other
     * string ID types and offers methods to convert to and from the ID type.
     */
    export type UserStringID = string
}

export namespace threads {
    export interface AssetContext {
        added: stid.AssetStringID[]
        removed: stid.AssetStringID[]
    }

    export interface AssetContextFull {
        added: q.ControlplaneSsAsset[]
        removed: q.ControlplaneSsAsset[]
    }

    /**
     * CreateThreadCmdParams is the params for the CreateThread command.
     */
    export interface CreateThreadCmdParams {
        projectId: stid.ProjectStringID
        label: string
    }

    export interface DirectoryContext {
        added: stid.DirectoryStringID[]
        removed: stid.DirectoryStringID[]
    }

    /**
     * Knowledge is used to communicate changes in the context made available for this message. Only differences should
     * be defined. All future messages will retain these adjustments until future instructions are provided to change
     * the context.
     */
    export interface Knowledge {
        assetContext: AssetContext
        projectContext: ProjectContext
        directoryContext: DirectoryContext
        organizationContext: OrganizationContext
        world: boolean
    }

    /**
     * KnowledgeFull is used to communicate changes in the context made available for this message. Only differences should
     * be defined. All future messages will retain these adjustments until future instructions are provided to change
     * the context. This type is similar to Knowledge but includes the full state of knowledge and is returned for
     * knowledge events to aid the UI in printing human friendly data.
     */
    export interface KnowledgeFull {
        assetContext: AssetContextFull
        /**
         * Projects      ProjectContextFull      `json:"projectContext"`
         * Dictionaries  DirectoryContextFull    `json:"directoryContext"`
         * Organizations OrganizationContextFull `json:"organizationContext"`
         */
        world: boolean
    }

    export type LifecycleState = string

    export interface Message {
        id: stid.MessageStringID
        threadId: stid.ThreadStringID
        metadata: Metadata
        version: number
        createdBy: stid.UserStringID
        isComplete: boolean
        recipients: Recipient[]
        /**
         * Content is a base64 encoded JSON string that can be decoded. Reference kind to determine how to decode.
         */
        content: string

        kind: MessageKind
        transformationQueue: string[]
        transformations: string[]
        textSuggestions: string[]
    }

    /**
     * MessageCmdParams is the params for the ReceiveMessage command.
     */
    export interface MessageCmdParams {
        threadId: stid.ThreadStringID
        /**
         * MessageContent is a base64 encoded JSON string that can be decoded. Reference kind to determine how to decode.
         */
        messageContent: string

        messageKind: MessageKind
        recipients: Recipient[]
    }

    export type MessageKind = string

    /**
     * MessageKinds represents any of the content kinds.
     * The primary use of this struct is to register all types so
     * encore can generate types for SDKs.
     * 
     * Note: There is an additional message kind `Error` which is not generated and conforms to the
     * error type returned by Encore.
     */
    export interface MessageKinds {
        "prompt_campaign_v1": MessagePromptTransformationV1
        prompt: MessagePromptV1
        text: MessageTextV1
        "knowledge_change": MessageKnowledgeV1
        "rename_thread": MessageRenameThreadV1
    }

    export interface MessageKnowledgeV1 {
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        projectId: stid.ProjectStringID
        threadId: stid.ThreadStringID
        messageId: stid.MessageStringID
        kind: MessageKind
        knowledgeFull: KnowledgeFull
        knowledge: Knowledge
        createdBy: stid.UserStringID
        progress: { [key: string]: ProcessingStatus }
    }

    export interface MessagePromptTransformationV1 {
        kind: MessageKind
        messageId: stid.MessageStringID
        prompt: string
        knowledge: Knowledge
        model: ai.Model
        transformationId: string
        createdBy: stid.UserStringID
    }

    export interface MessagePromptV1 {
        kind: MessageKind
        messageId: stid.MessageStringID
        /**
         * ThreadID stid.ID[stid.Thread] `json:"threadId"`
         */
        prompt: string

        knowledge: Knowledge
        model: ai.Model
        transformationId: string
        temperature: number
        "max_tokens": number
        "top_p": number
        "frequency_penalty": number
        "presence_penalty": number
        stop: string[]
        "logit_bias": { [key: number]: number }
        "context_window": number
        createdBy: stid.UserStringID
    }

    export interface MessageRenameThreadV1 {
        kind: MessageKind
        messageId: stid.MessageStringID
        label: string
        createdBy: stid.UserStringID
    }

    export interface MessageTextV1 {
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        projectId: stid.ProjectStringID
        threadId: stid.ThreadStringID
        messageId: stid.MessageStringID
        kind: MessageKind
        parts: string[]
        provenance: Provenance
        isDone: boolean
        label: string
        textSuggestions: string[]
        createdBy: stid.UserStringID
    }

    export interface Metadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
    }

    export interface OrganizationContext {
        added: stid.OrganizationStringID[]
        removed: stid.OrganizationStringID[]
    }

    export interface ProcessingStatus {
        percentage: number
        message: string
        lifecycleState: assets.LifecycleState
        isErr: boolean
        isDone: boolean
    }

    export interface ProjectContext {
        added: stid.ProjectStringID[]
        removed: stid.ProjectStringID[]
    }

    /**
     * Provenance represents the provenance of a message which represents custody of data and data about how it was processed.
     */
    export interface Provenance {
        processedByModel: ai.Model
        transformationId: string
        outputTokens: number
        inputTokens: number
    }

    export type Recipient = string

    /**
     * RenameThreadCmdParams is the params for the RenameThread command.
     */
    export interface RenameThreadCmdParams {
        threadId: stid.ThreadStringID
        curatedLabel: string
        generate: boolean
    }

    /**
     * ShareMethod represents the method of sharing a thread.
     */
    export type ShareMethod = string

    /**
     * ShareOptions represents the options for sharing a thread.
     */
    export type ShareOptions = string

    /**
     * ShareThreadCmdParams is the params for the ShareThread command.
     */
    export interface ShareThreadCmdParams {
        threadId: stid.ThreadStringID
        messageId: stid.MessageStringID
        shareMethod: ShareMethod
        shareOptions: ShareOptions
    }

    export interface ThreadState {
        threadId: stid.ThreadStringID
        projectId: stid.ProjectStringID
        tenantId: stid.OrganizationStringID
        organizationId: stid.OrganizationStringID
        label: string
        metadata: Metadata
        messages: Message[]
        lifecycleState: LifecycleState
        etag: string
        version: number
        activeAssets: q.ControlplaneSsAsset[]
    }
}

export namespace users {
    export interface Address {
        /**
         * Formatted is the unstructured value of the address. If this is not set by
         * the user it will be automatically constructed from structured values.
         */
        formatted: string

        poBox: string
        streetAddress: string
        extendedAddress: string
        city: string
        region: string
        postalCode: string
        country: string
        countryCode: string
        /**
         * Type The type of the email address and can be:
         * - `work` A work email address.
         * - `home` A personal home email address.
         * - `school` A school email address.
         * - `other` A custom email address not of the above types.
         */
        type: string

        metadata: Metadata
    }

    export interface AuthSyncResult {
        user: UserState
        workingContext: WorkingContext
        tokenRefreshRequired: boolean
        didSignUp: boolean
    }

    export interface EmailAddress {
        email: string
        /**
         * Type The type of the email address and can be:
         * - `work` A work email address.
         * - `home` A personal home email address.
         * - `school` A school email address.
         * - `other` A custom email address not of the above types.
         * - 'default' The default classification for an email address.
         */
        type: string

        verifiedAt: string
    }

    export interface ExternalIdentifier {
        id: string
        label: string
        metadata: Metadata
    }

    export type LifecycleState = string

    export interface Metadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
    }

    export interface OrganizationMembership {
        metadata: Metadata
        /**
         * ID The globally unique identifier of the organization.
         */
        organizationId: stid.OrganizationStringID

        tenantId: stid.OrganizationStringID
        /**
         * Occupation The user's occupation. Useful to help understand the user's role
         * in an organization and personalize the service to their needs.
         */
        occupation: string

        /**
         * Department The user's department. Useful to help understand the user's role
         * in an organization and personalize the service to their needs.
         */
        department: string

        /**
         * Title The user's title. Useful to help understand the user's role
         * in an organization and personalize the service to their needs.
         */
        title: string

        /**
         * CostCenter The user's cost center. Useful to help breakdown billing for organizations
         * with complex billing needs such as interdepartmental billing.
         */
        costCenter: string
    }

    /**
     * UserAuthSyncCmdParams represents a command to sync a user's auth data.
     */
    export interface UserAuthSyncCmdParams {
        /**
         * Email is the user's email address as provided by the authentication provider.
         */
        email: string

        /**
         * DisplayName is the user's display name as provided by the authentication provider.
         */
        displayName: string

        /**
         * IsEmailVerified is true if the user's email address has been verified as provided by the authentication provider.
         */
        isEmailVerified: boolean

        /**
         * PhotoURL is the URL to the user's profile picture as provided by the authentication provider.
         */
        photoURL: string

        /**
         * DeviceID is the unique identifier for the device that the user is using. The client is responsible for
         * managing this value. It is used to track the user's devices to aid with security and user experience.
         */
        deviceID: stid.DeviceStringID

        /**
         * UserAgent is the user agent string from the client.
         */
        userAgent: string

        /**
         * Locale is the user's locale as provided by the client.
         */
        locale: string

        /**
         * DefaultProjectName are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultProjectName: string

        /**
         * DefaultProjectDisplayName are used to create a new user's default organization and project. The client
         * should provide a localized string.  This value is always required but will have no effect on returning users.
         */
        defaultProjectDisplayName: string

        /**
         * DefaultProjectSlug are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultProjectSlug: slugs.Slug

        /**
         * DefaultOrganizationName are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultOrganizationName: string

        /**
         * DefaultOrganizationDisplayName are used to create a new user's default organization and project. The client
         * should provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultOrganizationDisplayName: string

        /**
         * DefaultOrganizationSlug are used to create a new user's default organization and project. The client should
         * provide a localized string. This value is always required but will have no effect on returning users.
         */
        defaultOrganizationSlug: slugs.Slug
    }

    export interface UserMetadata {
        /**
         * CreatedAt The time the user was created.
         */
        createdAt: string

        /**
         * CreatedBy The id of the user created the record.
         */
        createdBy: stid.UserStringID

        /**
         * ModifiedAt The time the user was last modified.
         */
        modifiedAt: string

        /**
         * ModifiedBy The id of the user who last made changes.
         */
        modifiedBy: stid.UserStringID

        /**
         * DeletedAt The time the user was deleted.
         */
        deletedAt: string

        deletedBy: stid.UserStringID
        /**
         * MarkedForAbuseAt The time the user was marked for abuse.
         */
        markedForAbuseAt: string

        /**
         * MarkedForAbuseBy The id of the user who marked the user for abuse.
         */
        markedForAbuseBy: stid.UserStringID
    }

    /**
     * UserState represents the state of a user.
     */
    export interface UserState {
        metadata: UserMetadata
        /**
         * ID The globally unique identifier of the user. It may be sourced from Firebase.
         */
        id: stid.UserStringID

        /**
         * Version The version of the user resource.
         */
        version: number

        /**
         * ETag The ETag of the user resource. Used for cache invalidation.
         */
        etag: string

        /**
         * AuthDisplayName The user's full name as sourced from an external auth system.
         */
        authDisplayName: string

        /**
         * PictureURL The URL of the user's profile picture sourced from an external auth system.
         */
        authPictureURL: string

        /**
         * Email The user's email address.
         */
        email: string

        /**
         * DisplayName The user's full name.
         */
        displayName: string

        /**
         * PhoneticName The user's phonetic name, if available, to help with pronunciation.
         */
        phoneticName: string

        /**
         * GivenName The user's first name.
         */
        givenName: string

        /**
         * FamilyName The user's last name.
         */
        familyName: string

        /**
         * Locale The user's preferred locale.
         */
        locale: string

        /**
         * PictureURL The URL of the user's profile picture.
         */
        pictureURL: string

        /**
         * Emails A list of all emails associated with the user.
         */
        emails: EmailAddress[]

        /**
         * Organizations A list of organizations the user is a member of.
         */
        organizations: OrganizationMembership[]

        /**
         * Addresses A list of addresses associated with the user.
         */
        address: Address[]

        markedForAbuseAt: string
        /**
         * MarkedForAbuseBy The id of the user who marked the user for abuse.
         */
        markedForAbuseBy: stid.UserStringID

        /**
         * Slug represents the vanity URL part for the user.
         */
        slug: slugs.Slug

        /**
         * LifecycleState The state of the user's lifecycle, assigned by the server.
         */
        lifecycleState: LifecycleState

        /**
         * PotentialScore is a score assigned to a user based on various signals such as their email address.
         * This score helps in determining the potential value of the user, influencing decisions on resource allocation
         * and the degree of free access granted to the user. For instance, a user from a large enterprise company
         * would typically have a higher score compared to a user signing up with a disposable email address.
         */
        potentialScore: number

        /**
         * ExternalIdentifiers A list of external identifiers associated with the user.
         */
        externalIdentifiers: ExternalIdentifier[]
    }

    /**
     * WorkingContext conveys the current working focus of the user.
     */
    export interface WorkingContext {
        "organization_id": stid.OrganizationStringID
        "tenant_id": stid.OrganizationStringID
        /**
         * DirectoryID is deprecated and should not be used for v2.
         */
        "directory_id": stid.DirectoryStringID

        /**
         * InteractionID is deprecated and should not be used for v2.
         */
        "interaction_id": stid.InteractionStringID

        /**
         * ProjectID is deprecated and should not be used for v2.
         */
        "project_id": stid.ProjectStringID
    }
}

export namespace xid {
    /**
     * ID represents a unique request id
     */
    export type ID = number[]
}



function encodeQuery(parts: Record<string, string | string[]>): string {
    const pairs: string[] = []
    for (const key in parts) {
        const val = (Array.isArray(parts[key]) ?  parts[key] : [parts[key]]) as string[]
        for (const v of val) {
            pairs.push(`${key}=${encodeURIComponent(v)}`)
        }
    }
    return pairs.join("&")
}

// makeRecord takes a record and strips any undefined values from it,
// and returns the same record with a narrower type.
// @ts-ignore - TS ignore because makeRecord is not always used
function makeRecord<K extends string | number | symbol, V>(record: Record<K, V | undefined>): Record<K, V> {
    for (const key in record) {
        if (record[key] === undefined) {
            delete record[key]
        }
    }
    return record as Record<K, V>
}


// mustBeSet will throw an APIError with the Data Loss code if value is null or undefined
function mustBeSet<A>(field: string, value: A | null | undefined): A {
    if (value === null || value === undefined) {
        throw new APIError(
            500,
            {
                code: ErrCode.DataLoss,
                message: `${field} was unexpectedly ${value}`, // ${value} will create the string "null" or "undefined"
            },
        )
    }
    return value
}

function encodeWebSocketHeaders(headers: Record<string, string>) {
    // url safe, no pad
    const base64encoded = btoa(JSON.stringify(headers))
      .replaceAll("=", "")
      .replaceAll("+", "-")
      .replaceAll("/", "_");
    return "encore.dev.headers." + base64encoded;
}

class WebSocketConnection {
    public ws: WebSocket;

    private hasUpdateHandlers: (() => void)[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        let protocols = ["encore-ws"];
        if (headers) {
            protocols.push(encodeWebSocketHeaders(headers))
        }

        this.ws = new WebSocket(url, protocols)

        this.on("error", () => {
            this.resolveHasUpdateHandlers();
        });

        this.on("close", () => {
            this.resolveHasUpdateHandlers();
        });
    }

    resolveHasUpdateHandlers() {
        const handlers = this.hasUpdateHandlers;
        this.hasUpdateHandlers = [];

        for (const handler of handlers) {
            handler()
        }
    }

    async hasUpdate() {
        // await until a new message have been received, or the socket is closed
        await new Promise((resolve) => {
            this.hasUpdateHandlers.push(() => resolve(null))
        });
    }

    on(type: "error" | "close" | "message" | "open", handler: (event: any) => void) {
        this.ws.addEventListener(type, handler);
    }

    off(type: "error" | "close" | "message" | "open", handler: (event: any) => void) {
        this.ws.removeEventListener(type, handler);
    }

    close() {
        this.ws.close();
    }
}

export class StreamInOut<Request, Response> {
    public socket: WebSocketConnection;
    private buffer: Response[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            this.buffer.push(JSON.parse(event.data));
            this.socket.resolveHasUpdateHandlers();
        });
    }

    close() {
        this.socket.close();
    }

    async send(msg: Request) {
        if (this.socket.ws.readyState === WebSocket.CONNECTING) {
            // await that the socket is opened
            await new Promise((resolve) => {
                this.socket.ws.addEventListener("open", resolve, { once: true });
            });
        }

        return this.socket.ws.send(JSON.stringify(msg));
    }

    async next(): Promise<Response | undefined> {
        for await (const next of this) return next;
        return undefined;
    }

    async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
        while (true) {
            if (this.buffer.length > 0) {
                yield this.buffer.shift() as Response;
            } else {
                if (this.socket.ws.readyState === WebSocket.CLOSED) return;
                await this.socket.hasUpdate();
            }
        }
    }
}

export class StreamIn<Response> {
    public socket: WebSocketConnection;
    private buffer: Response[] = [];

    constructor(url: string, headers?: Record<string, string>) {
        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            this.buffer.push(JSON.parse(event.data));
            this.socket.resolveHasUpdateHandlers();
        });
    }

    close() {
        this.socket.close();
    }

    async next(): Promise<Response | undefined> {
        for await (const next of this) return next;
        return undefined;
    }

    async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
        while (true) {
            if (this.buffer.length > 0) {
                yield this.buffer.shift() as Response;
            } else {
                if (this.socket.ws.readyState === WebSocket.CLOSED) return;
                await this.socket.hasUpdate();
            }
        }
    }
}

export class StreamOut<Request, Response> {
    public socket: WebSocketConnection;
    private responseValue: Promise<Response>;

    constructor(url: string, headers?: Record<string, string>) {
        let responseResolver: (_: any) => void;
        this.responseValue = new Promise((resolve) => responseResolver = resolve);

        this.socket = new WebSocketConnection(url, headers);
        this.socket.on("message", (event: any) => {
            responseResolver(JSON.parse(event.data))
        });
    }

    async response(): Promise<Response> {
        return this.responseValue;
    }

    close() {
        this.socket.close();
    }

    async send(msg: Request) {
        if (this.socket.ws.readyState === WebSocket.CONNECTING) {
            // await that the socket is opened
            await new Promise((resolve) => {
                this.socket.ws.addEventListener("open", resolve, { once: true });
            });
        }

        return this.socket.ws.send(JSON.stringify(msg));
    }
}
// CallParameters is the type of the parameters to a method call, but require headers to be a Record type
type CallParameters = Omit<RequestInit, "method" | "body" | "headers"> & {
    /** Headers to be sent with the request */
    headers?: Record<string, string>

    /** Query parameters to be sent with the request */
    query?: Record<string, string | string[]>
}

// AuthDataGenerator is a function that returns a new instance of the authentication data required by this API
export type AuthDataGenerator = () =>
  | string
  | Promise<string | undefined>
  | undefined;

// A fetcher is the prototype for the inbuilt Fetch function
export type Fetcher = typeof fetch;

const boundFetch = fetch.bind(this);

class BaseClient {
    readonly baseURL: string
    readonly fetcher: Fetcher
    readonly headers: Record<string, string>
    readonly requestInit: Omit<RequestInit, "headers"> & { headers?: Record<string, string> }
    readonly authGenerator?: AuthDataGenerator

    constructor(baseURL: string, options: ClientOptions) {
        this.baseURL = baseURL
        this.headers = {
            "Content-Type": "application/json",
        }

        // Add User-Agent header if the script is running in the server
        // because browsers do not allow setting User-Agent headers to requests
        if (typeof window === "undefined") {
            this.headers["User-Agent"] = "storytell-ai-platfor-cds2-Generated-TS-Client (Encore/v1.41.4)";
        }

        this.requestInit = options.requestInit ?? {};

        // Setup what fetch function we'll be using in the base client
        if (options.fetcher !== undefined) {
            this.fetcher = options.fetcher
        } else {
            this.fetcher = boundFetch
        }

        // Setup an authentication data generator using the auth data token option
        if (options.auth !== undefined) {
            const auth = options.auth
            if (typeof auth === "function") {
                this.authGenerator = auth
            } else {
                this.authGenerator = () => auth
            }
        }

    }

    async getAuthData(): Promise<CallParameters | undefined> {
        let authData: string | undefined;

        // If authorization data generator is present, call it and add the returned data to the request
        if (this.authGenerator) {
            const mayBePromise = this.authGenerator();
            if (mayBePromise instanceof Promise) {
                authData = await mayBePromise;
            } else {
                authData = mayBePromise;
            }
        }

        if (authData) {
            const data: CallParameters = {};

            data.headers = {};
            data.headers["Authorization"] = "Bearer " + authData;

            return data;
        }
        return undefined;
    }

    // createStreamInOut sets up a stream to a streaming API endpoint.
    async createStreamInOut<Request, Response>(path: string, params?: CallParameters): Promise<StreamInOut<Request, Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamInOut(this.baseURL + path + queryString, headers);
    }

    // createStreamIn sets up a stream to a streaming API endpoint.
    async createStreamIn<Response>(path: string, params?: CallParameters): Promise<StreamIn<Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamIn(this.baseURL + path + queryString, headers);
    }

    // createStreamOut sets up a stream to a streaming API endpoint.
    async createStreamOut<Request, Response>(path: string, params?: CallParameters): Promise<StreamOut<Request, Response>> {
        let { query, headers } = params ?? {};

        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                headers = {...headers, ...authData.headers};
            }
        }

        const queryString = query ? '?' + encodeQuery(query) : ''
        return new StreamOut(this.baseURL + path + queryString, headers);
    }

    // callAPI is used by each generated API method to actually make the request
    public async callAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
        let { query, headers, ...rest } = params ?? {}
        const init = {
            ...this.requestInit,
            ...rest,
            method,
            body: body ?? null,
        }

        // Merge our headers with any predefined headers
        init.headers = {...this.headers, ...init.headers, ...headers}


        // Fetch auth data if there is any
        const authData = await this.getAuthData();

        // If we now have authentication data, add it to the request
        if (authData) {
            if (authData.query) {
                query = {...query, ...authData.query};
            }
            if (authData.headers) {
                init.headers = {...init.headers, ...authData.headers};
            }
        }

        // Make the actual request
        const queryString = query ? '?' + encodeQuery(query) : ''
        const response = await this.fetcher(this.baseURL+path+queryString, init)

        // handle any error responses
        if (!response.ok) {
            // try and get the error message from the response body
            let body: APIErrorResponse = { code: ErrCode.Unknown, message: `request failed: status ${response.status}` }

            // if we can get the structured error we should, otherwise give a best effort
            try {
                const text = await response.text()

                try {
                    const jsonBody = JSON.parse(text)
                    if (isAPIErrorResponse(jsonBody)) {
                        body = jsonBody
                    } else {
                        body.message += ": " + JSON.stringify(jsonBody)
                    }
                } catch {
                    body.message += ": " + text
                }
            } catch (e) {
                // otherwise we just append the text to the error message
                body.message += ": " + String(e)
            }

            throw new APIError(response.status, body)
        }

        return response
    }
}

/**
 * APIErrorDetails represents the response from an Encore API in the case of an error
 */
interface APIErrorResponse {
    code: ErrCode
    message: string
    details?: any
}

function isAPIErrorResponse(err: any): err is APIErrorResponse {
    return (
        err !== undefined && err !== null && 
        isErrCode(err.code) &&
        typeof(err.message) === "string" &&
        (err.details === undefined || err.details === null || typeof(err.details) === "object")
    )
}

function isErrCode(code: any): code is ErrCode {
    return code !== undefined && Object.values(ErrCode).includes(code)
}

/**
 * APIError represents a structured error as returned from an Encore application.
 */
export class APIError extends Error {
    /**
     * The HTTP status code associated with the error.
     */
    public readonly status: number

    /**
     * The Encore error code
     */
    public readonly code: ErrCode

    /**
     * The error details
     */
    public readonly details?: any

    constructor(status: number, response: APIErrorResponse) {
        // extending errors causes issues after you construct them, unless you apply the following fixes
        super(response.message);
        
        // set error name as constructor name, make it not enumerable to keep native Error behavior
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/new.target#new.target_in_constructors
        Object.defineProperty(this, 'name', {
            value:        'APIError',
            enumerable:   false,
            configurable: true,
        })
        
        // fix the prototype chain
        if ((Object as any).setPrototypeOf == undefined) { 
            (this as any).__proto__ = APIError.prototype 
        } else {
            Object.setPrototypeOf(this, APIError.prototype);
        }
        
        // capture a stack trace
        if ((Error as any).captureStackTrace !== undefined) {
            (Error as any).captureStackTrace(this, this.constructor);
        }

        this.status = status
        this.code = response.code
        this.details = response.details
    }
}

/**
 * Typeguard allowing use of an APIError's fields'
 */
export function isAPIError(err: any): err is APIError {
    return err instanceof APIError;
}

export enum ErrCode {
    /**
     * OK indicates the operation was successful.
     */
    OK = "ok",

    /**
     * Canceled indicates the operation was canceled (typically by the caller).
     *
     * Encore will generate this error code when cancellation is requested.
     */
    Canceled = "canceled",

    /**
     * Unknown error. An example of where this error may be returned is
     * if a Status value received from another address space belongs to
     * an error-space that is not known in this address space. Also
     * errors raised by APIs that do not return enough error information
     * may be converted to this error.
     *
     * Encore will generate this error code in the above two mentioned cases.
     */
    Unknown = "unknown",

    /**
     * InvalidArgument indicates client specified an invalid argument.
     * Note that this differs from FailedPrecondition. It indicates arguments
     * that are problematic regardless of the state of the system
     * (e.g., a malformed file name).
     *
     * This error code will not be generated by the gRPC framework.
     */
    InvalidArgument = "invalid_argument",

    /**
     * DeadlineExceeded means operation expired before completion.
     * For operations that change the state of the system, this error may be
     * returned even if the operation has completed successfully. For
     * example, a successful response from a server could have been delayed
     * long enough for the deadline to expire.
     *
     * The gRPC framework will generate this error code when the deadline is
     * exceeded.
     */
    DeadlineExceeded = "deadline_exceeded",

    /**
     * NotFound means some requested entity (e.g., file or directory) was
     * not found.
     *
     * This error code will not be generated by the gRPC framework.
     */
    NotFound = "not_found",

    /**
     * AlreadyExists means an attempt to create an entity failed because one
     * already exists.
     *
     * This error code will not be generated by the gRPC framework.
     */
    AlreadyExists = "already_exists",

    /**
     * PermissionDenied indicates the caller does not have permission to
     * execute the specified operation. It must not be used for rejections
     * caused by exhausting some resource (use ResourceExhausted
     * instead for those errors). It must not be
     * used if the caller cannot be identified (use Unauthenticated
     * instead for those errors).
     *
     * This error code will not be generated by the gRPC core framework,
     * but expect authentication middleware to use it.
     */
    PermissionDenied = "permission_denied",

    /**
     * ResourceExhausted indicates some resource has been exhausted, perhaps
     * a per-user quota, or perhaps the entire file system is out of space.
     *
     * This error code will be generated by the gRPC framework in
     * out-of-memory and server overload situations, or when a message is
     * larger than the configured maximum size.
     */
    ResourceExhausted = "resource_exhausted",

    /**
     * FailedPrecondition indicates operation was rejected because the
     * system is not in a state required for the operation's execution.
     * For example, directory to be deleted may be non-empty, an rmdir
     * operation is applied to a non-directory, etc.
     *
     * A litmus test that may help a service implementor in deciding
     * between FailedPrecondition, Aborted, and Unavailable:
     *  (a) Use Unavailable if the client can retry just the failing call.
     *  (b) Use Aborted if the client should retry at a higher-level
     *      (e.g., restarting a read-modify-write sequence).
     *  (c) Use FailedPrecondition if the client should not retry until
     *      the system state has been explicitly fixed. E.g., if an "rmdir"
     *      fails because the directory is non-empty, FailedPrecondition
     *      should be returned since the client should not retry unless
     *      they have first fixed up the directory by deleting files from it.
     *  (d) Use FailedPrecondition if the client performs conditional
     *      REST Get/Update/Delete on a resource and the resource on the
     *      server does not match the condition. E.g., conflicting
     *      read-modify-write on the same resource.
     *
     * This error code will not be generated by the gRPC framework.
     */
    FailedPrecondition = "failed_precondition",

    /**
     * Aborted indicates the operation was aborted, typically due to a
     * concurrency issue like sequencer check failures, transaction aborts,
     * etc.
     *
     * See litmus test above for deciding between FailedPrecondition,
     * Aborted, and Unavailable.
     */
    Aborted = "aborted",

    /**
     * OutOfRange means operation was attempted past the valid range.
     * E.g., seeking or reading past end of file.
     *
     * Unlike InvalidArgument, this error indicates a problem that may
     * be fixed if the system state changes. For example, a 32-bit file
     * system will generate InvalidArgument if asked to read at an
     * offset that is not in the range [0,2^32-1], but it will generate
     * OutOfRange if asked to read from an offset past the current
     * file size.
     *
     * There is a fair bit of overlap between FailedPrecondition and
     * OutOfRange. We recommend using OutOfRange (the more specific
     * error) when it applies so that callers who are iterating through
     * a space can easily look for an OutOfRange error to detect when
     * they are done.
     *
     * This error code will not be generated by the gRPC framework.
     */
    OutOfRange = "out_of_range",

    /**
     * Unimplemented indicates operation is not implemented or not
     * supported/enabled in this service.
     *
     * This error code will be generated by the gRPC framework. Most
     * commonly, you will see this error code when a method implementation
     * is missing on the server. It can also be generated for unknown
     * compression algorithms or a disagreement as to whether an RPC should
     * be streaming.
     */
    Unimplemented = "unimplemented",

    /**
     * Internal errors. Means some invariants expected by underlying
     * system has been broken. If you see one of these errors,
     * something is very broken.
     *
     * This error code will be generated by the gRPC framework in several
     * internal error conditions.
     */
    Internal = "internal",

    /**
     * Unavailable indicates the service is currently unavailable.
     * This is a most likely a transient condition and may be corrected
     * by retrying with a backoff. Note that it is not always safe to retry
     * non-idempotent operations.
     *
     * See litmus test above for deciding between FailedPrecondition,
     * Aborted, and Unavailable.
     *
     * This error code will be generated by the gRPC framework during
     * abrupt shutdown of a server process or network connection.
     */
    Unavailable = "unavailable",

    /**
     * DataLoss indicates unrecoverable data loss or corruption.
     *
     * This error code will not be generated by the gRPC framework.
     */
    DataLoss = "data_loss",

    /**
     * Unauthenticated indicates the request does not have valid
     * authentication credentials for the operation.
     *
     * The gRPC framework will generate this error code when the
     * authentication metadata is invalid or a Credentials callback fails,
     * but also expect authentication middleware to generate it.
     */
    Unauthenticated = "unauthenticated",
}
