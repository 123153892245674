import type { Named } from "@repo/logger";
import {
  ExperimentKey,
  type Experiments,
  type PosthogExperimentsResponse,
} from "./experimentTypes";

// Coming from vite config in app.config.ts
declare const POSTHOG_API_KEY: string;

export const PostHogHost = "https://us.i.posthog.com";

/**
 * Fetches all feature flags for a user on the server using posthog's nodejs library
 * We don't need to do any fecthing on the client if we populate all the values in SSR
 *
 * @param userId - Distinct id to distinguish users (use deviceId for anonymous)
 * @returns
 */
export const fetchExperimentsServer = async (
  userId: string,
  override: Record<string, string | undefined>,
  logger: Named,
): Promise<Experiments | undefined> => {
  if (!import.meta.env.SSR) return;

  const { PostHog } = await import("posthog-node");

  const l = logger.child("fetchExperimentsServer");

  l.log("Initializing posthog-node");
  const client = new PostHog(POSTHOG_API_KEY, {
    host: PostHogHost,
    flushAt: 1,
    flushInterval: 0,
  });

  l.log(`Fetching experiments for user ${userId}`);

  const flags = await client.getAllFlagsAndPayloads(userId);

  const mapped = mapPosthogResponseToExperiments(
    flags as unknown as PosthogExperimentsResponse,
  );

  Object.entries(override).forEach(([key, value]) => {
    if (Object.values(ExperimentKey).includes(key as ExperimentKey)) {
      mapped[key as ExperimentKey] = {
        variant: value,
        payload: undefined,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      } as any;
    }
  });

  l.log("Experiments fetched", mapped);

  await client.shutdown();
  return mapped;
};

export const mapPosthogResponseToExperiments = (
  resp: PosthogExperimentsResponse,
) =>
  Object.entries(resp.featureFlags).reduce((obj, entry) => {
    const key = entry[0] as keyof Experiments;
    // biome-ignore lint/suspicious/noExplicitAny: Typescript doesn't like enumerated types inference :/
    const value = entry[1] as any;

    obj[key] = {
      variant: value,
      payload: resp.featureFlagPayloads[key],
      // biome-ignore lint/suspicious/noExplicitAny:  Typescript doesn't like enumerated types inference :/
    } as any;
    return obj;
  }, {} as Experiments);

export const mapExperimentsToPosthogFeatureFlags = (
  experiments: Experiments,
): PosthogExperimentsResponse["featureFlags"] =>
  Object.entries(experiments).reduce(
    (obj, exp) => {
      const key = exp[0] as keyof Experiments;
      const value = exp[1].variant;

      // biome-ignore lint/suspicious/noExplicitAny: Typescript doesn't like enumerated types inference :/
      obj[key] = value as any;
      return obj;
    },
    {} as PosthogExperimentsResponse["featureFlags"],
  );
